import Gallery from "@browniebroke/gatsby-image-gallery"
import { graphql, PageProps } from "gatsby"
import React from "react"
import { BiCalendarEvent } from "react-icons/bi"
import { Layout } from "../../components/Layout/Layout"
import { parseDate } from "../../utils/parseDate"
import { StrapiEvent } from "../../interfaces/Event"
import { NavigationTreeItem } from "../../interfaces/Navigations"

type DataProps = {
  strapiPastEvent: StrapiEvent
}

type PageContextProps = {
  node: NavigationTreeItem
  parentNode: NavigationTreeItem
  slug: string
}
const EventPageTemplate = ({
  pageContext,
  data,
}: PageProps<DataProps, PageContextProps>) => {
  const { slug } = pageContext
  // console.log(pageProps)
  const { strapiPastEvent } = data
  console.log(strapiPastEvent)

  const images: any = strapiPastEvent.gallery
    ? strapiPastEvent.gallery.map((file) => file.localFile.childImageSharp)
    : null
  return (
    <Layout activePage={{ slug: slug, title: strapiPastEvent.title }}>
      <div className="absolute left-0 ml-32 aspect-square w-[5vw] rounded-full bg-primary bg-opacity-10 "></div>
      <div className="absolute bottom-0 ml-20 aspect-square w-[20vw] rounded-full bg-primary bg-opacity-10 "></div>
      <div className="page-content">
        <div className=" prose prose-xl mt-20 mb-40 prose-h1:m-0">
          <div className="mb-4">
            <h1>{strapiPastEvent.title}</h1>
            <div className="my-4 flex items-center ">
              <BiCalendarEvent />
              <span>{parseDate(strapiPastEvent.from, strapiPastEvent.to)}</span>
            </div>

            <div className="mt-4 flex gap-2">
              {strapiPastEvent?.tags &&
                strapiPastEvent.tags.map((tag) => {
                  return <div className={`badge`}>{tag.name}</div>
                })}
            </div>
          </div>
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: strapiPastEvent.content.data?.content,
              }}
            />
          </div>
          {images && (
            <div>
              <h2>Galerie</h2>
              <div className="w-full ">
                <Gallery
                  images={images}
                  colWidth={50}
                  rowMargin={0}
                  imgClass="cursor-pointer"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}
export const result = graphql`
  query EventPageQuery($slug: String) {
    strapiPastEvent(slug: { eq: $slug }) {
      ...STRAPI_PAST_EVENTFragment
    }
  }
`

export default EventPageTemplate
